export const defaultNewsPreviewImage = {
  contentful_id: '4o9nsibGI07q3IrOU1d5UG',
  __typename: 'ContentfulAsset',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/6d085vujy22q/4o9nsibGI07q3IrOU1d5UG/407e75a69c5854aa42d5a5dc2a7bf532/sezzle-logo.png?w=512&h=205&q=50&fm=webp 512w',
          sizes: '700px',
          type: 'image/webp',
        },
      ],
      fallback: {
        src: 'https://images.ctfassets.net/6d085vujy22q/4o9nsibGI07q3IrOU1d5UG/407e75a69c5854aa42d5a5dc2a7bf532/sezzle-logo.png?w=512&h=205&q=50&fm=png',
        srcSet:
          'https://images.ctfassets.net/6d085vujy22q/4o9nsibGI07q3IrOU1d5UG/407e75a69c5854aa42d5a5dc2a7bf532/sezzle-logo.png?w=512&h=205&q=50&fm=png 512w',
        sizes: '700px',
      },
    },
    layout: 'fixed',
    backgroundColor: '#582888',
    width: 700,
    height: 280,
  },
};
