import React from 'react';
import style from 'styled-components';
import HeroTemplate1 from './components/Forms/HeroTemplate1';
import HeroTemplate2 from './components/Forms/HeroTemplate2';
import HeroTemplateForm from './components/Forms/HeroTemplateForm';
import renderCallOutOptions from '../renderCallOutOptions';
import { ContentfulContentType } from '../types/enums';
import { HeroSection as HeroSectionProps } from './types/types';
import { setColor } from '../../utils/setColor';

interface HeroContainerProps {
  backgroundColor: string;
}

const HeroContainer = style.section<HeroContainerProps>`
  background-color: ${(props: HeroContainerProps) =>
    props.backgroundColor !== 'none'
      ? setColor(props.backgroundColor, props)
      : 'inherit'};

  margin: 0 auto 2rem !important;
  padding-top: 20px;

  .image__hero {
    display: relative;
  }

  .content__wrapper {
    display: absolute;
  }

  .hero__callout {
    margin-bottom: 30px;
  }

  .hero__subnav {
    margin-bottom: 1rem;
  }
`;

const EmptyHero = style.div`
  margin-top: -50px;
`;

const HeroSection = ({
  callOut,
  subNavBar,
  hero,
  backgroundColor,
}: HeroSectionProps) => {
  const renderHeroOptions = (sectionType, heroFields) => {
    switch (sectionType) {
      case ContentfulContentType.HEROTEMPLATE1:
        switch (heroFields.template) {
          case 'Template 1':
            return <HeroTemplate1 {...heroFields} />;
          case 'Template 2':
            return <HeroTemplate2 {...heroFields} />;
          case 'Template and Form':
            return <HeroTemplateForm {...heroFields} />;
          default:
            return <HeroTemplate1 {...heroFields} />;
        }

      default:
        console.warn(`Section type not found: ${sectionType}`);
        return null;
    }
  };

  if (!hero && !subNavBar && !callOut) {
    return <EmptyHero />;
  }

  return (
    <HeroContainer backgroundColor={backgroundColor}>
      <div className="container">
        {subNavBar && (
          <div className="hero hero__subnav center">
            {renderCallOutOptions(
              subNavBar?.sys?.contentType?.sys?.id ||
                subNavBar?.contentfulContentType,
              subNavBar
            )}
          </div>
        )}
        {callOut && (
          <div className="hero hero__callout center">
            {renderCallOutOptions(
              callOut.sys?.contentType?.sys?.id ||
                callOut?.contentfulContentType,
              callOut
            )}
          </div>
        )}
        {hero &&
          renderHeroOptions(
            hero?.sys?.contentType?.sys?.id || hero?.contentfulContentType,
            hero
          )}
      </div>
    </HeroContainer>
  );
};

export default HeroSection;
