import React, { useContext } from 'react';
import { Typography, Box } from '@sezzle/sezzle-mui';
import { HeroText as HeroTextProps } from './types';
import { renderCTAOptions } from '../../../renderCTAOptions';
import { ScreenSizeConfiguratorContext } from '../../../ScreenSizeConfigurator';
import {
  StyledHeroTextContainer,
  StyledHeroTextButtonContainer,
  StyledHeroTextDescriptionContainer,
  StyledHeroTextButton,
} from './styles';

const PhoneCaptureForm = React.lazy(() => import('../../../PhoneCaptureForm'));

const HeroText = ({
  h1Header,
  h1HeaderFont,
  mobileCtas,
  buttonPlacementBelowSubcopy,
  bodyCopy1,
  bodyCopy2,
  bodyCopy3,
  bodyCopy1Font,
  bodyCopy2Font,
  bodyCopy3Font,
  phoneCaptureForm,
}: HeroTextProps) => {
  const { screenConfig } = useContext(ScreenSizeConfiguratorContext);

  let buttonWidth = React.useMemo(() => {
    if (screenConfig.isDesktop) {
      return '48%';
    }
    return '100%';
  }, [screenConfig]);

  h1HeaderFont = h1HeaderFont || 'SubHeadBoldSmall';
  bodyCopy1Font = bodyCopy1Font || 'BodyRegular';
  bodyCopy2Font = bodyCopy2Font || 'BodyRegular';
  bodyCopy3Font = bodyCopy3Font || 'BodyRegular';

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const { userAgent } = navigator;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
  }, []);

  return (
    <Box data-component-type="hero-text">
      <StyledHeroTextContainer
        container
        direction="column"
        alignItems="flex-start"
      >
        <Typography
          variant={h1HeaderFont}
          component="h1"
        >
          {h1Header}
        </Typography>
        {mobileCtas?.length !== 0 && !buttonPlacementBelowSubcopy && (
          <StyledHeroTextButtonContainer>
            {mobileCtas?.map((item, index) => (
              <StyledHeroTextButton
                key={index}
                sx={{ width: buttonWidth }}
              >
                {renderCTAOptions(
                  item?.sys?.contentType?.sys?.id ||
                    item?.contentfulContentType,
                  item,
                  true
                )}
              </StyledHeroTextButton>
            ))}
          </StyledHeroTextButtonContainer>
        )}
        <Box>
          <StyledHeroTextDescriptionContainer>
            {bodyCopy1 && (
              <Typography
                paragraph
                variant={bodyCopy1Font}
              >
                {bodyCopy1}
              </Typography>
            )}
            {bodyCopy2 && (
              <Typography
                paragraph
                variant={bodyCopy2Font}
              >
                {bodyCopy2}
              </Typography>
            )}
            {bodyCopy3 && (
              <Typography variant={bodyCopy3Font}>{bodyCopy3}</Typography>
            )}
          </StyledHeroTextDescriptionContainer>
        </Box>
      </StyledHeroTextContainer>
      {(!phoneCaptureForm || isMobile) &&
        mobileCtas?.length !== 0 &&
        buttonPlacementBelowSubcopy && (
          <StyledHeroTextButtonContainer
            container
            spacing={1}
          >
            {mobileCtas?.map((item, index) => (
              <StyledHeroTextButton
                item
                key={index}
                sx={{ width: buttonWidth }}
              >
                {renderCTAOptions(
                  item?.sys?.contentType?.sys?.id ||
                    item?.contentfulContentType,
                  item,
                  true
                )}
              </StyledHeroTextButton>
            ))}
          </StyledHeroTextButtonContainer>
        )}

      {!isMobile && phoneCaptureForm && (
        <PhoneCaptureForm
          key="phoneCaptureForm-HeroTemplate1"
          title={phoneCaptureForm.title}
          rating={phoneCaptureForm.rating}
          caption={phoneCaptureForm.caption}
          formPlaceholder={phoneCaptureForm.formPlaceholder}
          buttonText={phoneCaptureForm.buttonText}
          campaign={phoneCaptureForm.campaign}
          appDownloadLink={phoneCaptureForm.appDownloadLink}
          node_locale={phoneCaptureForm.node_locale}
        />
      )}
    </Box>
  );
};

export default HeroText;
