export enum ContentfulContentType {
  CARD = 'card',
  CTA = 'cta',
  CAROUSEL = 'carousel',
  CATEGORIZEDDISPLAY = 'categorizedDisplay',
  COLLAPSIBLELIST = 'collapsibleList',
  DOWNLOADCTA = 'downloadLink',
  EMBEDDEDSCRIPT = 'embeddedScript',
  HEADLINE = 'headline',
  HEADERANDDESCRIPTION = 'headerAndDescription',
  HEROSECTION = 'heroSection',
  HEROTEMPLATE1 = 'heroTemplate1',
  IMAGE = 'image',
  IMAGELINK = 'imageLink',
  IMAGELINKWITHTEXT = 'imageLinkWithText',
  IMAGEWITHTITLEANDDESCRIPTION = 'imageWithTitleAndDescription',
  LINKEDNAVIGATIONSTYLELINKS = 'linkedNavigationStyleLinks',
  NAVBAR = 'navBar',
  PARAGRAPH = 'paragraph',
  PAGESECTION = 'pageSection',
  REVENUECALCULATOR = 'revenueCalculator',
  SETOF = 'setOfWithAdvancedOptions',
  SIDEBYSIDEANDSTACKED = 'sideBySideAndStacked',
  SPECIFIC = 'specific',
  STATS = 'stats',
  WRAPPED = 'wrapped',
  PHONECAPTUREFORM = 'phoneCaptureForm',
  TEXT = 'text',
  ACCORDIONCONTAINER = 'accordionContainer',
  NEWSPAGETEMPLATE = 'newsPageTemplate',
  CONTENTFULASSET = 'ContentfulAsset',
}

export enum CTASelections {
  Link = 'Link',
  PrimaryButton = 'Primary Button',
  SecondaryButton = 'Secondary Button',
  MainNavBarNavigationLink = 'Main NavBar Navigation Link',
  Phone = 'Phone',
  Email = 'Email',
  Navigation = 'Navigation',
}

export enum CardTemplate {
  CARDTEMPLATE1 = 'Card Template 1',
  CARDTEMPLATE2 = 'Card Template 2',
  PREVIEWCARD = 'Preview Card',
}
