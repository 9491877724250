import React, { Suspense } from 'react';
import Card from './Card';
import CollapsibleList from './CollapsibleList';
import CTA from './CTA';
import DownloadCTA from './CTA/DownloadCTA';
import Headline from './Headline';
import Image from './Image';
import ImageLink from './ImageLink';
import ImageLinkWithText from './ImageLinkWithText';
import ImageWithTitleAndDescription from './ImageWithTitleAndDescription';
import Stats from './Stats';
import Wrapped from './Wrapped';
import { ContentfulContentType } from './types/enums';
import Paragraph from './Paragraph';
import Text from './Text';
import RevenueCalculator from './RevenueCalculator';
import lazyWithRetry from '../utils/lazyWithRetry';
import ClientOnly from './ClientOnly';
import AccordionContainer from './AccordionContainer';
import { defaultNewsPreviewImage } from '../utils/defaultObjects';

const AutoCarousel = React.lazy(() => import('./AutoCarousel'));
const Carousel = React.lazy(() => import('./Carousel'));

const EmbeddedScript = lazyWithRetry(() => import('./EmbeddedScript'));

export const renderSetOfComponent = (sectionType, fields) => {
  switch (sectionType) {
    case ContentfulContentType.CARD:
      return (
        <Card
          top={fields.top}
          link={fields.link}
          title={fields.title}
          subtitle={fields.subtitle}
          longSubTitle={fields.longSubTitle}
          borderColor={fields.borderColor}
          alignment={fields.alignment}
          template={fields.template}
          backgroundColor={fields.backgroundColor}
        />
      );
    case ContentfulContentType.CAROUSEL:
      switch (fields?.shouldAutoSlide) {
        case true:
          return (
            <AutoCarousel
              slides={fields.slides}
              mobileVisibleSlides={fields.mobileVisibleSlides}
              desktopVisibleSlides={fields.desktopVisibleSlides}
              delayBetweenTransitions={fields.delayBetweenTransitions}
            />
          );
        case false:
          return (
            <Carousel
              slides={fields.slides}
              mobileVisibleSlides={fields.mobileVisibleSlides}
              desktopVisibleSlides={fields.desktopVisibleSlides}
            />
          );
        default:
          return (
            <Carousel
              slides={fields.slides}
              mobileVisibleSlides={fields.mobileVisibleSlides}
              desktopVisibleSlides={fields.desktopVisibleSlides}
            />
          );
      }
    case ContentfulContentType.COLLAPSIBLELIST:
      return (
        <CollapsibleList
          listHeader={fields.listHeader}
          headerLevel={fields.headerLevel}
          list={fields.list}
        />
      );
    case ContentfulContentType.CTA:
      return <CTA fields={fields} />;
    case ContentfulContentType.DOWNLOADCTA:
      return <DownloadCTA fields={fields} />;
    case ContentfulContentType.EMBEDDEDSCRIPT:
      return (
        <ClientOnly>
          <Suspense fallback={<div />}>
            <EmbeddedScript
              scriptSource={fields.scriptSource}
              scriptInnerHtml={fields.scriptInnerHtml}
              scriptAppendToId={fields.scriptAppendToId}
              embeddedHtml={fields.embeddedHtml}
            />
          </Suspense>
        </ClientOnly>
      );
    case ContentfulContentType.HEADLINE:
      return (
        <Headline
          contentfulid={fields.contentfulid}
          header={fields.header}
          alignment={fields.alignment}
          subcopy={fields.subcopy}
          subCopySmall={fields.subCopySmall}
          link={fields.link}
        />
      );
    case ContentfulContentType.IMAGE:
      return <Image fields={fields} />;
    case ContentfulContentType.IMAGELINK:
      return (
        <ImageLink
          node_locale={fields.node_locale}
          shouldTextShow={fields.shouldTextShow}
          url={fields.imageUrl || fields.url}
          image={fields.image}
          textAlignment={fields.textAlignment}
          assistiveText={fields.assistiveText}
        />
      );
    case ContentfulContentType.IMAGELINKWITHTEXT:
      return (
        <ImageLinkWithText
          url={fields.imageUrl}
          image={fields.image}
          textAlignment={fields.textAlignment}
          displayText={fields.displayText}
        />
      );
    case ContentfulContentType.IMAGEWITHTITLEANDDESCRIPTION:
      return (
        <ImageWithTitleAndDescription
          title={fields.title}
          textAlign={fields.textAlign}
          imageMaxHeight={fields.imageMaxHeight}
          nestedHeader={fields.nestedHeader}
          description={fields.description}
          image={fields.image}
        />
      );
    case ContentfulContentType.STATS:
      return (
        <Stats
          statColor={fields.statColor}
          superStat={fields.superStat}
          supportingCopy={fields.supportingCopy}
          supportingStat={fields.supportingStat}
          disclaimerCopy={fields.disclaimerCopy}
          alignment={fields.alignment}
        />
      );
    case ContentfulContentType.WRAPPED:
      return <Wrapped fields={fields} />;
    case ContentfulContentType.PARAGRAPH:
      return (
        <Paragraph
          paragraph={fields.paragraph}
          bodyFontSize={fields.bodyFontSize}
        />
      );
    case ContentfulContentType.REVENUECALCULATOR:
      return (
        <RevenueCalculator
          header={fields.header}
          body={fields.body}
          aovFactor={fields.aovFactor}
          marketingFactor={fields.marketingFactor}
          incrementalSales={fields.incrementalSales}
          checkoutShare={fields.checkoutShare}
          disclaimer={fields.disclaimer}
          backgroundColor={fields.backgroundColor}
        />
      );
    case ContentfulContentType.NEWSPAGETEMPLATE:
      return (
        <Card
          top={{
            image:
              fields.previewImage ??
              fields.articleBody?.references[0] ??
              defaultNewsPreviewImage,
            node_locale: fields.node_locale,
            shouldTextShow: false,
            textAlignment: undefined,
            assistiveText: undefined,
          }}
          link={undefined}
          title={fields.articleTitle}
          subtitle={undefined}
          longSubTitle={undefined}
          borderColor=""
          alignment={undefined}
          template="Preview Card"
          backgroundColor={undefined}
          titleUrl={`/news/${fields.slug}/`}
        />
      );
    case ContentfulContentType.TEXT:
      return <Text {...fields} />;
    case ContentfulContentType.ACCORDIONCONTAINER:
      return <AccordionContainer {...fields} />;
    default:
      console.log(`Section type not found: ${sectionType}`);
      return null;
  }
};

export default renderSetOfComponent;
