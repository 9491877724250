import React, { useMemo } from 'react';
import { setPrefix } from '../../../utils/helpers';
import { LinkButtonProps } from './types';
import { defaultLocale } from '../../types/constants';
import { StyledMuiLink, StyledGatsbyLink } from './styles';

const LinkButton = ({
  node_locale,
  url,
  displayText,
  onClick,
  buttonSize,
  ariaControls,
  fontWeight,
  ...props
}: LinkButtonProps) => {
  const localePrefix =
    node_locale && node_locale === defaultLocale
      ? ''
      : `/${node_locale?.toLowerCase()}`;

  const fontSize = useMemo(() => {
    switch (buttonSize) {
      case 'small':
        return 13;
      case 'medium':
        return 15;
      case 'large':
        return 17;
      case 'x-large':
        return 27;
      default:
        return 17;
    }
  }, []);

  return url?.startsWith('/') ? (
    <StyledGatsbyLink
      to={`${localePrefix}${url}`}
      data-component-type="link"
      style={{ fontSize, fontWeight }}
    >
      {displayText}
    </StyledGatsbyLink>
  ) : (
    <StyledMuiLink
      onClick={onClick}
      href={setPrefix(props.function, url) || url}
      underline="hover"
      data-component-type="link"
      target="_blank"
      rel="noreferrer"
      sx={{
        fontSize,
        ...props,
      }}
      aria-controls={ariaControls}
      {...props}
    >
      {displayText}
    </StyledMuiLink>
  );
};

export default LinkButton;
